import jwt_decode from "jwt-decode";
export default {
  name: "auth",
  components: {
    jwt_decode,
  },
  data(){
    return{
      restTime:0,
      interval:null
    }
  },
  getJwt() {
    return jwt_decode(sessionStorage.getItem('keyPass'));
  },
  runExpiration(){
    this.interval = setInterval(()=>{
      this.restTime -= 1;
      if(this.restTime <= 0){
        sessionStorage.removeItem('keyPass');
        clearInterval(this.interval);
        window.location.replace("/portal")
      }else if(!sessionStorage.keyPass){
        clearInterval(this.interval);
        window.location.replace("/portal")
      }
    }, 1000)
  },
  countExpiration(){
    if(this.interval == null){
      this.runExpiration()
    }else{
      clearInterval(this.interval);
      this.runExpiration()
    }
  },
  getIsLogged() {

    if(!sessionStorage.keyPass){
      return false
    }

    let jwt = null
    try {
      jwt = this.getJwt();
    } catch (e) {
      return false;
    }

    if (jwt == '' || jwt == undefined || jwt == null) {
      return false;
    }

    let now = new Date().getTime() / 1000

    if (jwt.iat > now) {
      return false;
    }

    if (jwt.exp < now) {
      return false;
    }else{
      this.restTime = Math.floor(jwt.exp - now);
    }

    return true;
  }
}
