<template lang="html">
    <div id="menuData" ref="menuData" class="">
        <div class="topMenuMenuContent">
            <div class="squareAnimation"></div>
            <div class="menuInner">
                <div class="w-100 h-100 position-relative d-flex justify-content-between alig-items-center align-content-center">
                    <div class="h-100 col-2 pb-1 pt-1 ps-4 d-flex justify-content-start align-items-end">
                        <img :src="a13" class="h-75 object-fit-contain">
                    </div>
                    <div class="h-100 col-2 py-md-4 py-2 pe-5 d-flex justify-content-end align-items-center align-content-center" @click="closeMenu()">
                        <img :src="a14" class="h-50 object-fit-contain">
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomMenuMenuContent">
            <div class="videoBackground">
                <!-- <video autoplay muted loop playsinline>
                    <source :src="a1m" type="video/mp4">
                    <source :src="a1w" type="video/webm">
                </video> -->

                <img :src="a7" class="imageReplaceVideo">
            </div>
            <div class="videoOfus"></div>
            <div class="menuBottomInnerContent align-content-start align-content-md-center">
                
                <div class="menuOption mt-5 mt-md-4 col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3 " :class="this.$route.name == 'trademarks' ? 'active' : ''" @click="goTo('trademarks')"><span class="px-5 pt-3 pb-2 pt-md-5 pb-md-4">Marcas</span></div>

                <div class="menuOption col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3 " :class="this.$route.name == 'billing' ? 'active' : ''" @click="goTo('billing')"><span class="px-5 pt-3 pb-2 pt-md-5 pb-md-4">Facturación</span></div>

                <div class="menuOption col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3 " :class="this.$route.name == 'weare' ? 'active' : ''" @click="goTo('weare')"><span class="px-5 pt-3 pb-2 pt-md-5 pb-md-4">Nosotros</span></div>

                <div class="menuOption col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3" :class="this.$route.name == 'login' || this.$route.name == 'current' ? 'active' : ''" @click="goTo('login')"><span class="px-5 pt-md-5 pt-3 pb-2 pt-md-5 pb-md-4">BPTeam</span></div>

                <div class="menuOption mb-5 mt-mb-4 col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3 " :class="this.$route.name == 'career' ? 'active' : ''" @click="goTo('career')"><span class="px-5 pt-3 pb-2 pt-md-5 pb-md-4">Haz Carrera</span></div>

                <div class="menuOption mb-5 mt-mb-4 col-12 col-md-6 ts-2 py-2 ps-5 ps-md-3" v-if="user.name != '' && user.name != null" @click="closeSess()"><span class="px-5 pt-3 pb-2 pt-md-5 pb-md-4">Cerrar sesión</span></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"menuContent",
    props:{
        active:{
            default:false,
            type:Boolean
        },
        user:{
            default:{
                name:'',
                lastname:'',
            },
            type: Object
        }
    },
    watch:{
        active(){
            if(this.active){
                this.$refs.menuData.classList.add('active');
            }else{
                this.$refs.menuData.classList.remove('active');
            }
        },
    },
    data(){
        return{
            a7: require("@/assets/images/banners/a7.jpg"),
            a13:require('@/assets/images/a13.png'),
            a14:require('@/assets/images/a14.png'),
        }
    },
    methods:{
        closeSess(){
            sessionStorage.removeItem('keyPass');
            this.$parent.$emit('event', {type:'closeSesion'});
            this.$router.push({ name: 'home' })
        },
        closeMenu(){
            this.$emit('close');
        },
        goTo(view){
            this.$emit('close');
            if(this.$route.name != view){
                this.$router.replace({ name: view })
            }
        },
    }
}
</script>
<style lang="css">

    #menuData.active{
        display: flex !important;
    }

    #menuData{
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        flex-direction: column;
    }

    .topMenuMenuContent{
        position: relative;
        width: 100%;
        height: 100px;
        background-color: transparent;
        opacity: 0;
        z-index: 10;
        animation: fade 0.1s ease 1 forwards;
    }

    .videoBackground{
        width: 104%;
        left: -2%;
        top:-2%;
        position: fixed;
        height: 104%;
        filter: blur(5px);
        -webkit-filter: blur(5px);
    }

    .videoOfus{
        background-color: rgba(64, 64, 64, 0.41);
        width: 100%;
        left: 0;
        top:0;
        position: fixed;
        height: 100%;
    }

    .videoBackground .imageReplaceVideo{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .bottomMenuMenuContent{
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        width: 100%;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        flex: 1;
        z-index: 5;
        background-color: rgba(64, 64, 64, 0.2);
        opacity: 0;
        animation: fade 0.5s ease 1 0.5s forwards;
    }

    .menuOption{
        color:#fff;
        text-align: left;
        z-index: 10;
        font-weight: bold;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        cursor: pointer;
    }

    .menuOption span{
        display: inline;
        pointer-events: none;
    }

    .menuOption.active span{
        background-color: #ab1a2e;
    }

    .menuOption:hover span{
        background-color: #ab1a2e;
        opacity: 0.8;
    }

    .menuInner{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: var(--secondary);
        animation: fade 0.1s ease 1 0.5s forwards;
    }

    .squareAnimation{
        position: absolute;
        z-index: 2;
        right: 2.2em;
        top: calc(calc(100% - 40px) / 2); /* altura del square */
        pointer-events: none;
        width: 40px;
        opacity: 1;
        height: 40px;
        background-color: var(--secondary);
        animation:expand 0.5s ease 1 0.1s forwards;
    }

    .menuBottomInnerContent{
        position: relative;
        width: 100%;
        height: auto;
        min-height: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    @keyframes expand {
        90%{
            width: 100%;
        }
        100%{
            top: 0;
            right:0;
            width: 100%;
            height: 100%;
        }
    }

    @keyframes fade {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
</style>