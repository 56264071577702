import axios from "axios";

class request {

	apiUrl = "";
    defToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJFZmlzZW5zZSIsImF1ZCI6ImVmaXNlbnNlLmNvbSIsImlhdCI6MTY5MDA5MTgyMywiZXhwIjoxODQ3NzcxODIzLCJzdWIiOjAsInVzZXJDcm0iOjAsImVtYWlsIjoiIiwicm9sZSI6IiIsInNvdXJjZSI6InNpdGUiLCJpZExpY2Vuc2UiOjF9.pK3cfSSM5pdw2jDX9L5ibgDbXo5-EFVn3shhUg6gLes";

	settings = {}

	defSettings = {
		"url": "",
		"method": "GET",
		"timeout": 0,
	}

	constructor(api) {
		this.apiUrl = api;
	}

	jsonRequest = (req, parameter = null) => {

		this.settings = {};

		switch (req) {

			case "getLogin":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "Staff/Login";
                this.settings["headers"] = { 
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.defToken,
				}
				this.settings["data"] = JSON.stringify(parameter);
				break;

            case "matchCodeLogin":
                this.settings = {...this.defSettings};
                this.settings["method"] = "POST";
                this.settings["url"] = this.apiUrl + "Staff/CheckVerification";
                this.settings["headers"] = { 
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + this.defToken,
                }
                this.settings["data"] = JSON.stringify(parameter);
                break;

			case "getPayrollsList":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/CFDNomina";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "getPayrollView":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "bpt/CFDNomina/" + parameter[1];
				this.settings['responseType'] = 'arraybuffer';
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter[0],
				}
				break;

			case "getReferrals":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/Employee/Referred";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;
				
			case "getReferralCode":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/EmployeeReference/qr";
				this.settings['responseType'] = 'blob';
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "getAnticipationList":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/Anticipation";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "sendAnticipationRequest":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "Staff/Anticipation";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + parameter[0],
				}
				this.settings["data"] = JSON.stringify(parameter[1]);
				break;

			case "getBenefits":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/Employee/Benefits";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "getLoanData":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "bpt/Loan";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "getLoanParams":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "bpt/Loan/Calculate";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "sendLoanRequest":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "bpt/Loan/Calculate";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + parameter[0],
				}
				this.settings["data"] = JSON.stringify(parameter[1]);
				break;

			case "getInfoEmployee":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/Employee";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;

			case "sendCurriculum":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "bpt/HazCarrera";
				this.settings["headers"] = { 
					'Content-Type': "multipart/form-data",
				}
				this.settings["data"] = parameter;
				break;

			case "membership":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "Staff/Valid/"+parameter;
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + this.defToken,
				}
				break;

			default:
				return false
		}
		return this.settings
	}

	getLogin = (data = {}) => {
		let json = this.jsonRequest("getLogin", data);
		return this.petition(json, false)
	}

    matchCodeLogin = (data = {}) => {
		let json = this.jsonRequest("matchCodeLogin", data);
		return this.petition(json, false)
	}

	getPayrollsList = (data = '') => {
		let json = this.jsonRequest("getPayrollsList", data);
		return this.petition(json, false)
	}

	getPayrollView = (data = []) => {
		let json = this.jsonRequest("getPayrollView", data);
		return this.petition(json, false)
	}

	getReferrals = (data = '') => {
		let json = this.jsonRequest("getReferrals", data);
		return this.petition(json, false)
	}

	getReferralCode = (data = '') => {
		let json = this.jsonRequest("getReferralCode", data);
		return this.petition(json, false)
	}

	getAnticipationList = (data = '') => {
		let json = this.jsonRequest("getAnticipationList", data);
		return this.petition(json, false)
	}

	sendAnticipationRequest = (data = []) => {
		let json = this.jsonRequest("sendAnticipationRequest", data);
		return this.petition(json, false)
	}

	getBenefits = (data = '') => {
		let json = this.jsonRequest("getBenefits", data);
		return this.petition(json, false)
	}

	getLoanData = (data = '') => {
		let json = this.jsonRequest("getLoanData", data);
		return this.petition(json, false)
	}

	getLoanParams = (data = '') => {
		let json = this.jsonRequest("getLoanParams", data);
		return this.petition(json, true)
	}

	sendLoanRequest = (data = []) => {
		let json = this.jsonRequest("sendLoanRequest", data);
		return this.petition(json, false)
	}

	getInfoEmployee = (data = '') => {
		let json = this.jsonRequest("getInfoEmployee", data);
		return this.petition(json, true)
	}

	sendCurriculum = (data = []) => {
		let json = this.jsonRequest("sendCurriculum", data);
		return this.petition(json, false)
	}

	membership = (data = "") => {
		let json = this.jsonRequest("membership", data);
		return this.petition(json, false)
	}

	petition = async (json, watch=true) => {
		return new Promise(function(resolve, reject) {
			if(json){
				axios(json)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if(watch){
						console.log(error);
					}
					reject(error);
				})
			}else{
				let response = {data:false,code:500}
				resolve(response);
			}
		});
	}
}


export { request };