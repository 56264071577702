<template lang="html">
    <div class="d-flex flex-wrap w-100 align-items-start align-content-start justify-content-center">

        <loader :active="loading"></loader>
        <successm :active="success" :mess="msjSuccess"></successm>

        <portrait :image="a1" :phrase1="'HAZ'" :phrase2="'&nbsp;CARRERA'" :phrase3="'Intégrate a nuestro equipo'"></portrait>

        <div class="ts-6 color-1 fw-1 my-5 my-md-5">Para BPT las personas que forman el equipo<br class="d-md-none d-block"> son lo más importante<br class="d-md-block d-none"> para el éxito de <br class="d-md-none d-block">nuestra empresa, porque no vendemos comida,<br> vendemos servicio.</div>

        <div class="ts-6 color-2 fw-1 my-4 mt-md-5 mb-md-3">Muchos de nuestros Gerentes <br class="d-md-none d-block">comenzaron trabajando como parte<br class="d-md-none d-block"> del<br class="d-md-block d-none"> staff en nuestros restaurantes.</div>

        <div class="ts-6 color-2 fw-1 my-4 mt-md-3 mb-md-5">Somos una de las mejores opciones para <br class="d-md-none d-block">los jóvenes, ofreciéndoles la<br class="d-md-block d-none"> oportunidad de<br class="d-md-none d-block"> comenzar su carrera profesional de medio<br class="d-md-none d-block"> tiempo, tiempo<br class="d-md-block d-none"> completo y de fines de<br class="d-md-none d-block"> semana, mediante el pago por hora.</div>

         <div class="ts-6 color-1 fw-1 mt-md-5 mb-5 mt-4 mt-md-1">Actualmente empleamos<br class="d-md-none d-block"> a más de 800 colaboradores.</div>

         <div class="vision w-100 mt-5 mb-5 d-flex flex-wrap">
            <span class="filler d-md-none"></span>
            <div class="visionLegends w-100 d-flex flex-wrap align-items-center align-content-center">
                <span class="filler"></span>
                <div class="col-12 d-flex flex-wrap px-2 mb-2 align-items-center align-content-center">
                    <div class="col-12 col-md-3 color-1 fw-bold ts-12 mb-2 mb-md-0 animation effect-4">Misión</div><div class="col-12 col-md-8 col-lg-7 col-xl-6 ts-10 text-center text-md-start color-3 fw-2 px-4 px-md-0">Generar momentos WOW a través del servicio, la calidad y experiencia.</div>
                </div>
                <span class="filler"></span>
                <div class="col-12 d-flex flex-wrap  px-2 mt-2 align-items-center align-content-center">
                    <div class="col-12 col-md-3 color-1 fw-bold ts-12 mb-2 mb-md-0 animation effect-4">Visión</div><div class="col-12 col-md-8 col-lg-7 col-xl-6 ts-10 text-center text-md-start color-3 fw-2 px-4 px-md-0">Ser un referente de operación de restaurantes con marcas líderes e innovadoras en el mercado nacional</div>
                </div>
                <span class="filler"></span>
            </div>
            <span class="filler d-md-none"></span>

            <!-- <video autoplay muted loop playsinline>
                <source :src="a1m" type="video/mp4">
                <source :src="a1w" type="video/webm">
            </video> -->

            <img :src="a3" class="imageReplaceVideo">

        </div>

        <div class="color-1 fw-bold text-center w-100 my-5 ts-12 animation effect-4">Nuestras vacantes</div>

        <div class="jobList col-12 d-flex align-items-start align-content-start py-5 mt-3">
            <template v-for="column in jobs">
                <div class="col-4 d-flex flex-wrap align-items-start align-content-start ">
                    <template v-for="job in column">
                        <div class="w-100 my-0 my-md-1 ts-7 b-b py-2 fw-bold color-3 text-start d-flex align-items-center animation effect-1" style="height:80px">
                            <span class="col-2 col-md-3 h-100 d-inline-block"></span>{{job}}
                        </div>
                    </template>
                </div>
            </template>
        </div>

         <div class="col-12 d-flex align-items-start align-content-start py-5 mt-3">
                <div class="col-12 col-md-6 d-flex flex-wrap align-items-start align-content-start">
                        <div class="w-100 my-0 my-md-1 ts-7 py-2 fw-bold color-3 text-md-start text-center d-flex align-items-center justify-content-center justify-content-md-start">
                            <span class="col-0 col-md-2 h-100 d-inline-block"></span><span class="color-1 fw-bold text-md-start text-center ts-12">Recomendados de nuestros<br>colaboradores:</span>
                        </div>
                </div>
        </div>

        <div class="col-10 ps-5 ts-6 color-3 fw-1 my-1 my-md-5 text-start">En BPT recompensaremos a nuestros colaboradores que nos recomienden a sus amigos y familiares para formar parte de nuestro equipo.</div>

        <span class="filler d-none d-md-block"></span>
        <span class="filler d-none d-md-block"></span>

        <div class="color-1 fw-bold text-center w-100 my-5 ts-12">Beneficios de colaboradores</div>

        <div class="d-flex w-100 flex-wrap justify-content-evenly align-content-start align-items-start px-4 px-md-5">

            <div class="d-flex w-100 flex-wrap justify-content-start align-content-stretch align-items-stretch px-0 px-md-5">
 
                <template v-for="benefit in benefits">
                    <div class="d-flex justify-content-center align-items-stretch align-content-stretch col-md-3 col-6 mt-4 mt-sm-2 mt-md-4 mb-3 mb-md-0 mb-lg-2 mb-xl-5">
                        <div class="col-10 rectangle color-4 backgroundForced" :style="{'background-color':benefit.color}">
                            <span class="d-flex align-items-center align-content-center flex-wrap px-4 px-md-3">
                                <img class="animation effect-8" :src="benefit.icon" alt="">
                                <div class="w-100 ts-4 fw-bold text-center d-flex justify-content-center mb-2 mb-md-3 color-5 animation effect-9">{{benefit.title}}</div>
                            </span>
                        </div>
                    </div>
                </template>

             </div>
        </div>

        <div class="banner w-100 d-flex flex-wrap my-5 py-5 align-items-center align-content-center">
            <div class="col-12 ts-6 color-4 fw-1 my-1 mt-md-5 mb-3 text-center">Postúlate y mándanos un correo a <br class="d-md-none d-block">rh@bptgroup.mx</div>
            <div class="col-12 ts-6 color-4 fw-1 my-1 mb-md-5 mt-3 text-center">o llámanos al (999) 48-44-04 Ext. 709 *</div>
        </div>

        <span class="filler d-none d-md-block"></span>

        <div class="col-md-7 col-12 ps-5 ts-12 color-1 fw-1 my-1 my-md-5 mb-4 text-start">Envíanos tu curriculum</div>
        <div class="col-md-7 col-12 ps-md-5 color-1 fw-1 my-1 my-md-5 text-start d-flex justify-content-center">

            <form class="curriculumForm col-10 col-md-7 mb-5" onsubmit="return false">
                <div class="mb-3">
                    <label for="nameInput" class="form-label color-6 ts-10">Nombre</label>
                    <input v-model="formData.name" type="text" class="form-control ts-9 font-3" id="nameInput">
                    <div class="form-text d-none">*Solo puedes introducir texto.</div>
                </div>
                 <div class="mb-3">
                    <label for="inputEmail" class="form-label color-6 ts-10">Correo electrónico</label>
                    <input v-model="formData.email" type="email" class="form-control ts-9 font-3" id="inputEmail">
                    <div class="form-text d-none">*El campo esta vacío</div>
                </div>
                <div class="mb-3 form-check ps-4 mt-5 d-flex align-items-stretch align-content-stretch">
                    <input v-model="formData.acepted" type="checkbox" class="form-check-input ms-0 font-3" id="termsCheckout">
                    <label class="form-check-label color-6 ts-8 d-flex align-items-center ms-3 align-content-center" for="termsCheckout">Acepta el Aviso de Privacidad</label>
                </div>
                <div class="mb-3 mt-4 font-3">
                    <input class="form-control" type="file" ref="inputFile" id="inputFile" accept=".pdf,.doc,.docx">
                </div>
                <div class="w-100 color-2 ts-8 fw-3 mt-5">Máximo 2 megas de peso y el formato<br class="d-md-block d-none"> debe ser .pdf o .doc</div>
                <button :class="formData.acepted ? 'opacity-100' : 'opacity-25'" @click="sendCurriculum()" :disabled="!formData.acepted" class="col-4 bg-dark color-4 ts-8 px-4 py-3 mt-3 font-3 fw-bold">Enviar</button>
                <div v-if="errorText != ''" class="w-100 mt-2 mb-2 py-4 ts-9 color-1 fw-bold d-flex flex-wrap align-items-center align-content-center" v-html="errorText"></div>
            </form>

        </div>

        <footer-info class="mt-5"></footer-info>

    </div>
</template>
<script>
import loader from "@/components/common/loader.vue";
import portrait from "@/components/common/portrait.vue";
import footerInfo from "@/components/common/footer.vue";
import successm from "@/components/common/successModal.vue";

export default {
    name:"career",
    emits:['event'],
    props:{
        con: {
            default: {},
            type: Object
        },
    },
    components:{
        portrait,
        footerInfo,
        loader,
        successm
    },
    mounted(){
        this.$scrollInTop();
        this.$parent.$parent.$emit('event', {type:'updateObserver'})
    },
    methods:{

        resetForm(){
            this.formData ={
                name:"",
                email:"",
                acepted:false,
            }
            this.$refs.inputFile.value = '';
        },

        sendCurriculum(){
            this.errorText = "";

            if(this.$refs.inputFile.files.length > 0){
                if(
                    this.$refs.inputFile.files[0]['type']!=='application/pdf' && 
                    this.$refs.inputFile.files[0]['type']!=='application/x-pdf' && 
                    this.$refs.inputFile.files[0]['type']!=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    this.errorText += "*Solo se pueden recibir achivos .pdf y .doc<br><br>"
                }
                if(!this.$refs.inputFile.files[0].size / 10000 > 2){
                    this.errorText += "*Solo se pueden recibir achivos .pdf y .doc con peso menor a 2 megabytes<br><br>"
                }
            }else{
                this.errorText += "<span class='d-block col-1'>*</span><span class='col-11'>Debe adjuntar su curriculum</span><br><br>"
            }

            if(!this.validateNow(
                this.formData.name,
                "names"
            )){
                this.errorText += "<span class='d-block col-1'>*</span><span class='col-11'>El nombre debe tener solo letras y espacios y hasta un máximo de 50 caracteres</span><br><br><br>"
            }; 

            if(!this.validateNow(
                this.formData.email,
                "email"
            )){
                this.errorText += "<span class='d-block col-1'>*</span><span class='col-11'>El email es inválido</span><br><br>"
            }; 

            if(this.errorText == ""){
                this.loading = true;
                let form = new FormData();
                form.append('cv', this.$refs.inputFile.files[0]);
                form.append('name', this.formData.name);
                form.append('email', this.formData.email);
                this.con.sendCurriculum(form).then((res)=>{
                    if(res.code == 200){
                        if(res.data === true){
                            this.success = true;
                            this.resetForm();
                        }
                    }else if(res.code == 400){
                        for(let i in res.msj){
                            this.errorText += "<span class='d-block col-1'>*</span><span class='col-11'>"+res.msj[i]+"</span><br><br>";
                        }
                    }
                    this.loading = false;
                }).catch((err)=>{
                    this.errorText += "<span class='d-block col-1'>*</span><span class='col-11'>"+err+"</span><br><br>";
                    this.loading = false;
                })
            }else{
                return false;
            }
        },
        validateNow(stringInput, type) {
            let reg = null;
            let res = false;
            let string = String(stringInput);
            string = string.trim();
            switch (type) {
                case "names":
                    reg = new RegExp(/^[a-zA-ZÀ-ú ]{3,50}$/g);
                    break;
                case "email":
                    reg = new RegExp(
                        /^[a-zA-Z0-9_.]{3,64}@[a-z0-9]{3,64}\.[a-z0-9]{2,10}(?:\.[a-z]{2,10}|)$/g
                    );
                    break;
            }
            res = reg.test(string);
            if (res && string != null) {
                return true;
            } else {
                return false;
            }
        },
    },
    data(){
        return{
            msjSuccess:'curriculum enviado...',
            success:false,
            loading:false,
            errorText:"",
            a3: require("@/assets/images/banners/a3.jpg"),
            a1: require("@/assets/images/a1-2.jpg"),
            jobs:[
                [
                    'Repartidor',
                    'Server (mesero)',
                    'Ayudante de server',
                    'Hostess',
                    'Nanny',
                    'Telefonista',
                ],

                [
                    'Cocinero (a)',
                    'Expo',
                    'Dishwasher',
                    'Panadero',
                    'Bartender',
                    'Barista',
                ],

                [
                    'Ayudante de barra',
                    'Cajera',
                    'Auxiliar de limpieza',
                ]
            ],
            formData:{
                name:"",
                email:"",
                acepted:false,
            },
            benefits:[
                {
                    icon:require('@/assets/images/a38.png'),
                    title:"CAJA DE AHORRO",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a39.png'),
                    title:"DÍA LIBRE Y VALE DE CUMPLEAÑOS",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a40.png'),
                    title:"CAPACITACION PAGADA",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a41.png'),
                    title:"BENEFICIO PARA PADRES Y MADRES",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a42.png'),
                    title:"DESCUENTO EN RESTAURANTE",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a43.png'),
                    title:"PRIMA DOMINICAL",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a44.png'),
                    title:"AGUINALDO",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a45.png'),
                    title:"DÍAS DE LUTO",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a46.png'),
                    title:"SEGURO DENTAL",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a47.png'),
                    title:"SEGURO DE GASTOS MEDICOS",
                    extra:"(GERENCIA GENERAL)",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a48.png'),
                    title:"APOYO PSICOLOGICO",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a49.png'),
                    title:"SEGURO DE VIDA",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a50.png'),
                    title:"VACACIONES ACORDE A LA LEY",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a51.png'),
                    title:"BECAS EN LA UNID",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a52.png'),
                    title:"DESCUENTOS EN EL HOSPITAL EL FARO",
                    color:"#e1dfdc"
                },
                {
                    icon:require('@/assets/images/a53.png'),
                    title:"CAMPAÑA DE ÓPTICA VISUAL",
                    color:"#fff"
                },
                {
                    icon:require('@/assets/images/a54.png'),
                    title:"CONVENIOS CON GIMNASIOS",
                    color:"#e1dfdc"
                },
                
            ]
        }
    }
}
</script>
<style lang="css">
    .vision{
        display: flex;
        position: relative;
        min-height: 300px;
        background-color: black;
    }
    .vision .imageReplaceVideo{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 0;
        filter: blur(5px);
        -webkit-filter: blur(5px);
    }
    .visionLegends{
        position: relative;
        margin-top: 8%;
        min-height: 84%;
        margin-bottom: 8%;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.85);
    }
    .filler{
        width: 100%;
        height: 60px;
    }
    .rectangle{
        position: relative;
    }

    .squareContent{
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        left: 0;
        top:0
    }

    .rectangle img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .jobList{
        background-color: #e1dfdc;
    }
    .b-b{
        position: relative;
    }
    .b-b::after{
        position: absolute;
        bottom: 0;
        left: 15%;
        content:"";
        width: 70%;
        height: 2px;
        background-color: #fff;
    }
    .banner{
        background-color: var(--primary);
    }

    .curriculumForm input[type=text]{
        min-height:60px;
        background-color: rgba(0,0,0,.03);
    }
    .curriculumForm input[type=email]{
        min-height:60px;
        background-color: rgba(0,0,0,.03);
    }
    .curriculumForm input[type=text]:focus, .curriculumForm input[type=email]:focus{
        background-color:#fff;
    }

    .curriculumForm input[type=checkbox]{
        height:25px;
        width: 25px;
        border-radius:4px;
        border:3px solid var(--quinary);
    }
    .curriculumForm input[type=file]::file-selector-button {
        border:1px solid var(--quinary);
        border-radius: .2em;
        background-color: rgba(0,0,0,.03);;
        letter-spacing:0.05em;
    }
    #inputFile{
        border:0;
        outline:none;
        border-radius:0;
        background-color:#fff;
        transform: scaleY(1.1);
        letter-spacing:0.05em;
    }
</style>