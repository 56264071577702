import { createRouter, createWebHistory } from 'vue-router'
import auth from '../assets/js/auth.js'
import HomeView from '../views/home.vue'
import trademarks from '../views/trademarks.vue'
import weare from '../views/weare.vue'
import portal from '../views/portal.vue'
import career from '../views/career.vue'
import billing from '../views/billing.vue'
import NotFound from '../views/404.vue'
import memberCard from '../views/member.vue'

const DEFAULT_TITLE = 'BPT Group';

const routes = [
    {
        path: '/404', name: 'NotFound', component: NotFound
    },
    {
        path: '/:catchAll(.*)', redirect:'404'
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group" },
    },
    {
        path: '/trademarks',
        name: 'trademarks',
        component: trademarks,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Nuestras marcas" },
    },
    {
        path: '/weare',
        name: 'weare',
        component: weare,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Nosotros" },
    },
    {
        path: '/portal',
        name: 'portal',
        component: portal,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Portal colaboradores" },
        children: [
            {
                path: "",
                name: "login",
                component: () => import('../components/login.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Entrar" },
            },
            {
                path: "current",
                name: "current",
                component: () => import('../components/employee.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: true, title: "BPT Group | Mi portal" },
            },
        ]
    },
    {
        path: '/career',
        name: 'career',
        component: career,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Haz carrera" },
    },
    {
        path: '/billing',
        name: 'billing',
        component: billing,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Facturación" },
    },
    {
        path: '/memberCard',
        name: 'memberCard',
        component: memberCard,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "BPT Group | Membresia Colaborador" },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // siempre vuelve al top de la página
        return { top: 0 };
    }
})


router.beforeEach((to, from) => {

    if (to.meta.requiresAuth && (!auth.getIsLogged() )) {
        return {
            path: '/portal',
        }
    }
    else if (to.name === "login" && (auth.getIsLogged()) ) {
        return {
            path: '/portal/current',
        }
    }
    else if (to.meta.requiresAuth && (auth.getIsLogged())) {
        auth.countExpiration();
    }
})

router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
