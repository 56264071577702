<template lang="html">

    <div class="d-flex flex-wrap w-100 align-items-start align-content-start justify-content-center">

        <portrait :image="a1" :phrase1="'Nuestras'" :phrase2="'&nbsp;Marcas'" :phrase3="'EXPERIENCIAS Inigualables'"></portrait>

        <div class="my-md-5 my-0 w-100 d-flex justify-content-center">
            <div class="col-12 col-md-10">
                <img class="image-1 p-3 p-xl-5 animation effect-8" :src="a25">
                <img class="image-1 p-3 p-xl-5 animation effect-8" :src="a26">
                <img class="image-1 p-3 p-xl-5 animation effect-8" :src="a28">
                <img class="image-1 p-1 p-xl-2 animation effect-8" :src="a27">
            </div>
        </div>

        <div class="ts-6 color-1 fw-1 my-4 my-md-5 animation effect-1">Descubre nuestras marcas y encuentra en <br class="d-md-none d-block">nuestros restaurantes un <br class="d-md-block d-none">lugar donde la <br class="d-md-none d-block">excelencia culinaria se une a la hospitalidad.</div>

        <template v-for="store in stores">
            <mark-block :image="store.logo" :title="store.title" :description="store.description" :link="store.link" :phrase="store.phrase" :stores="store.stores" :color1="store.color1" :color2="store.color2" :color3="store.color3" :color4="store.color4" :pictures="store.images"></mark-block>
        </template>

        <footer-info></footer-info>
        
    </div>
    
</template>
<script>
import portrait from "@/components/common/portrait.vue";
import markBlock from "@/components/common/markBlock.vue";
import footerInfo from "@/components/common/footer.vue";

export default {
    name:"trademarks",
    emits:['event'],
    props:{
        con: {
            default: {},
            type: Object
        },
    },
    components:{
        portrait,
        markBlock,
        footerInfo
    },
    mounted(){
        this.$parent.$parent.$emit('event', {type:'updateObserver'});
        this.$scrollInTop();
    },
    data(){
        return{
            a1: require("@/assets/images/a1-2.jpg"),
            a25: require("@/assets/images/a25.png"),
            a26: require("@/assets/images/a26.png"),
            a27: require("@/assets/images/a27.png"),
            a28: require("@/assets/images/a28.png"),
            stores:{
                'bostons':{
                    logo:require("@/assets/images/a20.png"),
                    title:'Boston’s es el casual dining #1 de Canadá.',
                    description:'Si buscas un motivo para no cocinar, necesitas un lugar especial para ver el juego con los amigos o pasar tiempo con la familia, en Boston’s nos hemos asegurado que haya un lugar para todos. Ya sea animando a su equipo favorito en nuestro bar, celebrando una «victoria de la vida» en nuestro comedor, o simplemente relajándose en nuestras terrazas, estamos diseñados para ayudarte a escapar e incluso si no puedes estar aquí, puedes disfrutar de Boston’s en cualquier lugar con nuestro servicio de comida para llevar y entrega a domicilio. Nuestra promesa es hacer fans, no solo a los nuevos huéspedes si no también a los habituales.',
                    link:'www.bostons.com.mx',
                    phrase:'We’ll Make You a Fan',
                    stores:{
                        'Mérida':[
                            'Altabrisa',
                            'Dorada',
                            'Caucel',
                            'Kukulcan'
                        ],
                        'Campeche':[
                            'Plaza Galerías'
                        ],
                        'CDMX':[
                            'Patio Santa Fe',
                            'Gran Terraza Coapa',
                            'Patio Universidad'
                        ],
                        'Querétaro':[
                            'Juriquilla'
                        ]
                    },
                    color1:'#06369d',
                    color2:'#942933',
                    color3:'#fff',
                    color4:'#fff',
                    images:[
                        require("@/assets/images/bostons/a1.png"), require("@/assets/images/bostons/a2.png"), require("@/assets/images/bostons/a3.png"), require("@/assets/images/bostons/a4.png")
                    ]
                },
                'parroquia':{
                    logo:require("@/assets/images/a21.png"),
                    title:'La Parroquia de Veracruz',
                    description:'El #Tintintin generado por el golpe de las cucharas al contacto con los vasos de cristal es el sonido que más se distingue al atravesar las puertas de La Parroquia de Veracruz. Una combinación entre confianza y familiaridad es uno de los mayores secretos del éxito del lugar. La Parroquia se ha caracterizado por mantener como pilar fundamental los sabores de Veracruz. Aquí encuentran lo que buscan: la tradicional comida veracruzana con un excelente café. La Parroquia de Veracruz es sinónimo de experiencias y tradiciones.',
                    link:'www.laparroquiadeveracruz.com',
                    phrase:'El café más famoso de México',
                    stores:{
                        'Mérida':[
                            'City Center',
                            'Caucel',
                        ],
                        'CDMX':[
                            'Centro histórico Sucursal Brasil',
                            'Centro histórico Sucursal Palma',
                        ],
                        'Campeche':[
                            'Sucursal Galerías'
                        ],
                    }, 
                    color1:'#41201F',
                    color2:'#f4f1eb',
                    color3:'#41201F',
                    color4:'#fff',
                    images:[
                        require("@/assets/images/parroquia/a1.png"), require("@/assets/images/parroquia/a2.png"), require("@/assets/images/parroquia/a3.png"), require("@/assets/images/parroquia/a4.png")
                    ]
                },
                'santaclara':{
                    logo:require("@/assets/images/a23.png"),
                    title:'Santa Clara',
                    description:'Los helados Santa Clara están elaborados con ingredientes de alta calidad (100% leche de vaca). Contamos con 38 sabores y cuidamos de cada detalle para que disfrutes su delicioso sabor y cremosidad.',
                    link:'www.santaclara.com.mx',
                    phrase:'Los santos  momentos se disfrutan con el mejor sabor',
                    stores:{
                        'Tabasco':[
                            'Villahermosa',
                        ],
                        'Mérida':[
                            'Altabrisa'
                        ]
                    },
                    color1:'#fff',
                    color2:'#AD7557',
                    color3:'#fff',
                    color4:'#000',
                    images:[
                        require("@/assets/images/santaclara/a1.jpg"), require("@/assets/images/santaclara/a2.jpg"), require("@/assets/images/santaclara/a3.jpg"), require("@/assets/images/santaclara/a4.jpg")
                    ]
                },
                'sushiroll':{
                    logo:require("@/assets/images/a22.png"),
                    title:'Sushi Roll',
                    description:'En Sushi Roll se tiene la filosofía de ofrecer productos originales y nutritivos, manteniéndonos en el mercado nacional e internacional con recetas novedosas para brindar así un toque personal a cada platillo, comprometiéndonos en conservar una atmosfera cálida y fresca para nuestros clientes y colaboradores siendo fuente de ideas que innovan continuamente para así lograr nuestra sustentabilidad, apegándonos a nuestras políticas, códigos y reglamentos que rigen nuestro actuar.',
                    link:'www.sushiroll.com.mx',
                    phrase:'That’s How I Roll ',
                    stores:{
                        'Campeche':[
                            'Plaza Galerías',
                        ],
                    },
                    color1:'#93d50a',
                    color2:'#000000 ',
                    color3:'#fff',
                    color4:'#fff',
                    images:[
                        require("@/assets/images/sushiroll/a1.png"), require("@/assets/images/sushiroll/a2.png"), require("@/assets/images/sushiroll/a3.png"), require("@/assets/images/sushiroll/a4.png")
                    ]
                }
            }
        }
    }
}
</script>
<style lang="css">
    .image-1{
        width: 25%;
        object-fit: cover;
    }
</style>