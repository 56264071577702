<template lang="html">
    <div class="modal fade show" id="successModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-md px-md-0 px-5">
            <div class="modal-content d-flex justify-content-center align-items-start align-content-start">
                <img :src="a66" class="icon">
                <div class="modal-body mt-5 mb-4 w-100 d-flex flex-wrap justify-content-center">
                    <div class="w-100 color-8 ts-6 text-center d-flex justify-content-center mb-3">
                        Listo
                    </div>
                    <div class="w-100 color-5 ts-9 mb2" v-html="mess">
                    </div>
                    <button class="sucessButton py-2 mt-4 ts-11" @click="hideModal()">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { Modal } from "bootstrap";

export default {
    name:"successModal",
    props:{
        active:{
            default:false,
            type:Boolean
        },
        mess:{
            default:'',
            type:String
        }
    },
    watch:{
        active(){
            if(this.active){
                this.modal.show()
            }else{
                this.modal.hide()
            }
        }
    },
    mounted(){
        this.modal = new Modal(document.getElementById("successModal"), {
            backdrop: "static",
            keyboard: false,
        });
    },
    data(){
        return{
            a66:require('@/assets/images/a66.png'),
            modal:null,
        }
    },
    methods:{
        hideModal(){
            this.modal.hide();
        }
    }
}
</script>
<style lang="css">
    #successModal .sucessButton{
        width: 50%;
        border: 1px solid var(--undecagenary);
        outline: none;
        background-color: #fff;
        border-radius: 10px;
    }
    #successModal .sucessButton:hover{
        background-color: var(--undecagenary);
        color:#fff;
    }
    #successModal .icon{
        position:absolute;
        top: -50px;
        left: 50%;
        margin-left: -50px;
        height: 100px;
        width: 100px;
        opacity: 0;
        animation:getDown 0.5s ease-out 1 forwards;
    }
    @keyframes getDown {
        0%{
            transform: scale(2);
            opacity: 0;
        }
        100%{
            transform: scale(1);
            opacity: 1;
        }
    }
</style>